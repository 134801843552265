 @charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-family: "slick" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next{
	position: absolute;
	display: block;
	width: 8.75px;
	height: 15px;
	border-radius: 0;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 2;
	transition: $time;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	opacity: .75;

	&.slick-disabled{
		pointer-events: none;
		opacity: .25;
	}

	&:hover{
		opacity: 1;
	}
}

.slick-prev {
	left: -27px;
	background-image: url(../images/slide-prev.svg);

	@media (max-width: 1820px){
		left: -10px;
	}

	[dir="rtl"] & {
		left: auto;
		right: -68px;

		@media (max-width: 1230px){
			right: -38px;
		}
	}
}

.slick-next {
	right: -27px;
	background-image: url(../images/slide-next.svg);

	@media (max-width: 1820px){
		right: -10px;
	}

	[dir="rtl"] & {
		left: 0;
		right: auto;
	}
}

/* Dots */

//.slick-dotted.slick-slider {
//	margin-bottom: 30px;
//}

.slick-dots {
	position: absolute;
	bottom: 41px;
	list-style: none;
	display: block;
	text-align: right;
	padding: 0;
	margin: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 20px;

	@media (max-width: $b3){
		height: 15px;
	}

	li{
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;

		@media (max-width: $b3){
			width: 15px;
			height: 15px;
		}

		button{
			border: 0;
			display: block;
			height: 20px;
			width: 20px;
			border-radius: 40px;
			outline: none;
			line-height: 0px;
			cursor: pointer;
			font-size: 0;
			padding: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: $time;
			opacity: 1;
			cursor: pointer;
			background-color: transparent;
			border: 2px solid #cac8c9;

			@media (max-width: $b3){
				width: 15px;
				height: 15px;
			}

			&:hover, &:focus{
				outline: none;
				background-color: $black;
				border-color: $black;
			}
		}

		&.slick-active button{
			background-color: $black;
			border-color: $black;
		}
	}
}
