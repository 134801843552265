@font-face {
	font-family: 'Titillium Web';
	src: url('../fonts/TitilliumWeb-ExtraLight.woff2') format('woff2'),
		url('../fonts/TitilliumWeb-ExtraLight.woff') format('woff'),
		url('../fonts/TitilliumWeb-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	src: url('../fonts/TitilliumWeb-Light.woff2') format('woff2'),
		url('../fonts/TitilliumWeb-Light.woff') format('woff'),
		url('../fonts/TitilliumWeb-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	src: url('../fonts/TitilliumWeb-Regular.woff2') format('woff2'),
		url('../fonts/TitilliumWeb-Regular.woff') format('woff'),
		url('../fonts/TitilliumWeb-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	src: url('../fonts/TitilliumWeb-SemiBold.woff2') format('woff2'),
		url('../fonts/TitilliumWeb-SemiBold.woff') format('woff'),
		url('../fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	src: url('../fonts/TitilliumWeb-Bold.woff2') format('woff2'),
		url('../fonts/TitilliumWeb-Bold.woff') format('woff'),
		url('../fonts/TitilliumWeb-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	src: url('../fonts/TitilliumWeb-Black.woff2') format('woff2'),
		url('../fonts/TitilliumWeb-Black.woff') format('woff'),
		url('../fonts/TitilliumWeb-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('../fonts/SourceSansPro-Regular.woff') format('woff'),
		url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

//BREAK POINTS
$b1:				1064px + 32px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				768px - 1px; //768px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS
$red:				#cc0033;

$green:				#139B00;

$blue-light:		#507AAC;
$blue:				#0262A7;
$blue-dark:			#054A83;

$yellow:			#FFFF00;

$grey-light:		#E5E5E5;
$grey:				#666666;
$grey-dark:			#4C4D4F;
$grey-darker:		#2D2D2D;

$white:				#fff;
$black:				#000;

$colour: 			$grey;
$main-bg-colour:	#F2F2F3;

$font-tit:			'Titillium Web', sans-serif;
//extra light - 200
//light - 300
//regula - 400
//semi - 600
//bold - 700
//black - 900

$font-source:		'Source Sans Pro', sans-serif;
//regula - 400

$main-font:			$font-tit;
$alt-font:			$font-source;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	36px !default;

//!!PAGE WIDTH
$base-width:		1064px;

//!!SITE TRANSITIONS
$time:				.25s;

//!!FONT SIZE DIVIDER
$divider:			1.25;
$divider-big:		1.4;
