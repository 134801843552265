//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: relative;
	top: 0;
	left: 0;
	transition: $time;
	background-color: $blue;
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	position: relative;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 189px;
	margin-top: 20px;

	@media (max-width: $b4){
		order: 2;
			margin-top: 17px;
	}

	img{
		display: block;
		width: 100%;
	}
}

.menu-top-outer{
	padding-top: 0;
	padding-bottom: 0;

	@media (min-width: $b3 + 1){
		.container{
			width: calc(100% - 18px - 18px);
		}
	}

//	@media (max-width: $b4){
//		width: calc(100% - 16px - 16px);
//	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
		padding-bottom: 21px;

		@media (max-width: $b3 + 100px){
			padding-bottom: 18px;
		}

		@media (max-width: $b4){
			flex-direction: column;
			align-items: center;
		}

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #3280B8;

			@media (max-width: $b3){
				background-color: white;
			}
		}
	}
}

.menu-bottom-outer{
	padding-top: 0;
	padding-bottom: 0;


	@media (min-width: $b3 + 1){
		.container{
			width: calc(100% - 18px - 18px);
		}
	}

	.col-12{
		position: relative;

		@media (min-width: $b3 + 1){
			padding-left: 0;
			padding-right: 0;
		}

		@media (max-width: $b3){
			justify-content: flex-end;
			display: flex;
		}

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: white;
		}
	}
}

.links{
	display: flex;
	justify-content: flex-end;
	align-items: center;

	@media (max-width: $b3 + 100px){
		flex-wrap: wrap;
		width: 360px;
	}

	@media (max-width: $b4){
		justify-content: center;
	}

	a{
		color: white;
		padding: 8px 15px;
		display: flex;
		align-items: center;
		text-decoration: none;

		@media (max-width: $b2){

			@media (min-width: $b4 + 1){

				&:nth-of-type(1){
					order: 2;
				}

				&:nth-of-type(2){
					order: 1;
				}

				&:nth-of-type(3){
					order: 3;

				}

				&:nth-of-type(4){
					order: 4;
				}

				&:nth-of-type(5){
					order: 5;
				}
			}
		}

		@media (max-width: $b4){
			@include font-size(12);
			padding: 8px 4px;
		}

		@media (max-width: 385px){
			@include font-size(11);
			padding: 8px 3px;
		}

		&:hover,
		&:focus{
			color: $blue;
			background-color: $yellow;

			.number{
				color: $blue;
			}
		}

		.number{
			color: $yellow;
			margin-left: .2em;
			transition: $time;
		}

		i{
			display: block;
			margin-right: 5px;

			@media (max-width: $b4){
				display: none;
			}
		}

		&.basket{

			i{
				background-image: url(../images/basket.svg);
				background-repeat: no-repeat;
				width: 18px;
				height: 17px;
			}
		}

		&.account{

			i{
				background-image: url(../images/account.svg);
				background-repeat: no-repeat;
				width: 16px;
				height: 16px;
			}
		}

		&.search{

			i{
				background-image: url(../images/search.svg);
				background-repeat: no-repeat;
				width: 13px;
				height: 13px;
			}
		}
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	width: 100%;
	display: flex;
	z-index: 01;
	position: relative;

	@media (max-width: $b3){
		overflow: auto;
		position: absolute;
		top: 100%;
		width: 100%;
		background-color: $blue;
		left: 0;
		right: 0;
		overflow: hidden;
		max-height: 0;
		transition: $time;

		.menu-open &{
			max-height: 500px;
			border-bottom: 1px solid white;
		}
	}
}

#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-parent,
#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

	> a{

		.dark &{
			color: black;

			&:after{
				background-color: black;
			}
		}

		.light &{
			color: white;

			&:after{
				background-color: white;
			}
		}
	}
}

.head-ad-container{

	@media (max-width: $b1){
		display: none;
	}
}

.menu-images{
	display: none;
}

ul.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	justify-content: flex-start;
	position: relative;
	width: 100%;

	@media (max-width: $b3){
		flex-direction: column;
		width: 100%;
	}

	li{
		@include font-size(0);
		line-height: 1em;
		text-align: center;
		flex-grow: 1;

		@media (max-width: $b3){
			transition: $time;
		}

		&:hover,
		&:focus{

			> a{
				background-color: $yellow;
				color: $blue;
			}
		}

		&.menu-item-has-children{
			position: relative;

			@media (min-width: $b3 + 1){

				&:hover,
				&:focus{

					.sub-menu{
						transition: $time;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			@media (max-width: $b3){

				&:hover,
				&:focus{
					background-color: $yellow;

					a{
						color: $blue;

						&:after{
							color: $blue;
							background-image: url(../images/menu-arrow-blue.svg);
						}
					}
				}

				&.open{
					background-color: $yellow;

					> a{
						color: $blue;

						&:after{
							color: $blue;
							transform: rotate(90deg);
							background-image: url(../images/menu-arrow-blue.svg);
							background-repeat: no-repeat;
						}
					}

					> ul{
						max-height: 900px;

						a{
							color: $blue;
						}
					}
				}

				> a{
					padding-left: 10px;
					padding-right: 10px;
					position: relative;

					&:after{
						content: '';
						display: inline-block;
						font-family: 'Font Awesome\ 5 Free';
						position: absolute;
						right: -18px;
						@include font-size(10);
						pointer-events: none;
						font-weight: 100;
						width: 16px;
						height: 16px;
						top: 9px;
						transition: $time;
						background-image: url(../images/menu-arrow-white.svg);
						background-repeat: no-repeat;
						background-position: 50% 50%;
					}
				}
			}

			.sub-menu{
				transition: 0s;
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: 100%;
				left: 0;
				width: 300px;
				max-width: 300px;
				background-color: $yellow;
				z-index: 11;

				@media (max-width: $b3){
					width: 100%;
					max-width: 100%;
					position: static;
					max-height: 0;
					overflow: hidden;
					margin: 0;
					opacity: 1;
					visibility: visible;
				}

				a{
					color: $blue;
					text-align: left;
					display: block;

					@media (max-width: $b3){
						display: inline-block;
						color: $blue;
					}

					&:hover,
					&:focus{
						color: white;
						background-color: $blue;
					}
				}
			}
		}


		> a{
			@include font-size(16);
			width: auto;
			color: $white;
			line-height: 1.2em;
			text-decoration: none!important;
			font-weight: 400;
			position: relative;
			font-family: $main-font;
			padding: 0 0;
			display: block;
			position: relative;
			padding: 8px 15px;
			transition: $time;

			@media (max-width: 1100px){

				@media (min-width: $b2 + 1){
					@include font-size(14);
					padding: 8px 6px;
				}
			}

			@media (max-width: $b2){

				@media (min-width: $b3 + 1){
					@include font-size(13);
					padding: 8px 3px;
				}
			}

			@media (max-width: $b3){
				@include font-size(14);
				display: inline-block;
			}
		}
	}
}
