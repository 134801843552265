  //!!FORM
::-webkit-input-placeholder {
	@include font-size(14);
}

:-moz-placeholder { /* Firefox 18- */
	@include font-size(14);
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(14);
}

:-ms-input-placeholder {
	@include font-size(14);
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 16px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 6px 8px;
	@include font-size(14);
	font-weight: 400;
	color: $black;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: 1px solid #ddd;
	background-color: white;
	border-radius: 0;
	line-height: 1.3em;
	-webkit-appearance: none;
	box-shadow: none;
}

textarea{
	height: 125px;
	min-height: 125px;
	min-width: 100%;
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

//
//!!SELECT START
//
select{
	padding-right: 20px;
	appearance: none;
	@include svg_img("../images/select-icon");
	background-position: 100% 50%;
	line-height: 32px;
	height: 32px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: 5px;
		padding-bottom: 5px;
		color: $colour;
	}
}
//
//!!SELECT END
//

label{
	display: block;
	@include font-size(14);
	line-height: 2em;

	.gfield_required{
		display: none;
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend .btn;
	margin-bottom: 0;
	width: 100%;
}

.submit-con{
	display: flex;
	justify-content: flex-end;

	[type="submit"]{
		transform: translateY(-100%);
		margin-top: -$input-bottom-margin;

		@media (max-width: $b3){
			transform: translateY(0%);
		}
	}
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 25px!important;
		position: relative;
		cursor: pointer;
		line-height: 18px;
		@include inline;
		margin-bottom: 5px;

		&:before{
			position: absolute;
			@include svg_img("../images/checkbox");
			content: "";
			width: 18px;
			height: 18px;
			left: 0;
			background-position: 0% 50%;
		}
	}

	&:checked + label:before,
	&:checked + .wpcf7-list-item-label:before,
	&:checked + span:before,
	& + label.checked:before{
		background-position: 100% 50%;
	}
}

input[type="radio"]{

	& + .wpcf7-list-item-label,
	& + label{

		&:before{
			@include svg_img("../images/radio");
		}
	}
}

//
//!!RADIO & CHECKBOX END
//

.gform_wrapper.gform_wrapper{
	margin-bottom: 0;
	width: 100%;

	.gform_heading h3{
		@include font-size(20);
		font-family: $alt-font;
		font-weight: 900;
		letter-spacing: 0;
		margin-top: 0;
		margin-bottom: .85em;
		text-align: left;

		@media (max-width: $b2){
			@include font-size(22);
			margin-bottom: 0.3em;
		}
	}


	.gform_description{
		@include font-size(17.35);
		display: block;
		margin: 0 auto 1.25em;
		line-height: 1.2em;

		@media (max-width: $b2){
			@include font-size(15.4);
			margin: 0 auto 0.95em;
		}
	}

	.validation_error{
		@include font-size(14);
		color: $red;
		padding-bottom: 10px;
		font-family: $main-font;
	}

	form{
		margin-bottom: 0;

		ul{
			padding: 0;
		}

		.columns{
			float: left;
			width: 48.1%;

			.gfield.gfield.gfield.gfield {
				float: none;
				width: 100%;
				clear: none;

				&.small.small.small{
					float: left;
					width: 48.2%;

					& + .small{
						margin-left: 3.6%;
					}
				}
			}

			~ .columns{
				float: right;
				width: 48.2%;
			}
		}

		.gfield_checkbox{
			margin-bottom: 12px;
		}

		.gform_fields{
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;


			.gfield_label{
				//margin-bottom: 13px;

				.info{

					+ .info{
						display: none;
					}
				}
			}

			.ginput_container{
				width: 100%;
			}

			.gfield{
				list-style: none;
				margin-bottom: 0;
				font-size: 0;
				padding-left: 0;
				background-image: none;

				.smaller{
					@include font-size(12.5);
					display: block;
				}

				&.medium.medium.medium{
					width: 48.2%;
				}

				&.clear.clear.clear.clear{
					float: left;
					clear: both;
				}
				&.gfield_html.gfield_html.gfield_html,
				&.large.large.large{
					float: left;
					clear: both;
					width: 100%;
				}

				&.small.small.small{
					width: 32.2%;
					float: left;

					@media (max-width: $b4){
						width: 100%;
					}

					+ .small{
						margin-left: 1.7%;
						clear: none;

						@media (max-width: $b4){
							width: 100%;
						}
					}
				}

				&.gfield_html.gfield_html{
					width: 100%;
				}

				&.gform_validation_container{
					display: none;
				}

				.gfield_required{
					color: $red;
					color: $colour;
				}

				&.gfield_error{
					position: relative;

					.validation_message{
						@include font-size(14);
						color: $red;
						position: absolute;
						bottom: 7px;
						left: -2px;
						line-height: 1em;
						padding-left: 14px;
						font-family: $main-font;
						display: none;
					}
				}

				&.gform_validation_container{
					overflow: hidden;
					max-height: 0;
				}

				input,
				input.input-text,
				textarea,
				select{
					width: 100%;
				}

				&.radio-checkbox{

					&.medium.medium{

					}

					&.large.large.large.large.large.large{
						width: 100%;

						.gfield_radio{
							margin-bottom: 46px;
						}

						.gfield_label{
							display: block;
							width: 100%;
							max-width: none;

							.gfield_required{
								display: none;
							}
						}
					}

					.gfield_label{
						display: block;
						width: auto;
						max-width: 310px;
					}
				}

//				&.has-description:not(.gfield_error).has-description:not(.gfield_error).has-description:not(.gfield_error).has-description:not(.gfield_error){
//
//					.gfield_description{
//						width: 48.2%;
//						@include font-size(16);
//						line-height: 1.37em;
//						position: absolute;
//						right: 0;
//						bottom: 20px;
//
//
//						@media (max-width: $b4){
//							width: 100%;
//							float: none;
//						}
//					}
//				}
			}
		}

		.gform_footer{
			padding-top: 13px;

			@media (max-width: $b3){
				padding-top: 0;
			}
		}

		.gfield_checkbox,
		.gfield_radio{

			li{
				list-style: none;
				@include inline;
				vertical-align: middle;

				&:last-of-type{

					label{
						padding-right: 0;
					}
				}
			}
		}
	}

	.gfield_visibility_hidden{
		height: 0;
		width: 0;
		overflow: hidden;
		opacity: 0;
	}
}

.gform_wrapper.gform_wrapper form .gfield_checkbox,
.gform_wrapper.gform_wrapper form .gfield_radio{

	li{
		padding-left: 0;
		display: block;
	}
}

