//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'main-menu';
@import 'footer';
@import 'general-overights'; /* stuff like float-left, clear-right, align-center */
@import 'buttons';
@import 'forms';
@import 'slick';
@import 'slick-theme';
//@import 'fancybox';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.v-desktop{
	@media only screen and (max-width: 949px){
		display: none;
	}
}

.v-mobile{
	overflow: hidden;
	@media only screen and (min-width: 950px){
		display: none;
	}
	// .image-side img{
	// 	width: 100vw !important;
	// 	max-width: 100vw !important;
	// 	position:relative !important;
	// 	left: 50% !important;
	// 	transform:translateX(-50%) !important;
	// }
	.tabs{
		padding-left:36px !important;
		padding-right: 36px !important;
		& + .read-more-con{
			padding-left:36px;
			padding-right: 36px;
			margin-top: 32px;
		}
	}
	// .slider{
	// 	visibility: visible;
	// 	overflow: visible;
	// 	&::after{
	// 		content:'';
	// 		display: block;
	// 		position:absolute;
	// 		top: 0;
	// 		right: -35px;
	// 		bottom:0;
	// 		height: 100%;
	// 		width: 35px;
	// 		background-color:$white;
	// 		z-index: 1000;
	// 	}
	// 	*{
	// 		overflow: visible;
	// 	}
	// }
	&.tab-one-section,
	&.tab-two-section{
		.title{
			h1,h2,h3,h4,h5,h6{
				font-size:4.2rem;
				line-height: 0.9;
				@media only screen and (max-width: 370px){
					font-size:3.2rem;
				}
			}
		}
		.image-side,
		.text-side{
			padding-right: 0 !important;
			padding-left: 0 !important;
		}
		.image-side:not(:last-child) {

			padding-right:0 !important;
			overflow: visible !important;
			margin-bottom: 25px !important;
			margin-left: auto !important;
			margin-right: auto !important;
			width: 100% !important;
			max-width: 100% !important;
			flex: 0 0 100% !important;
			img{
				width: calc(100% + 70px) !important;
				margin-left: -35px !important;
				margin-right: -35px !important;
				max-width: calc(100% + 70px) !important;
			}
			.quote{
				bottom:40px;
			}

		}

		.image-side:last-child {
			padding-right:10px !important;
			overflow: visible !important;
			img{
				width: calc(100% + 35px) !important;
				margin-left: -35px !important;
				max-width: calc(100% + 35px) !important;
			}
			.quote{
				bottom:40px !important;
				left: auto !important;
				right: 0 !important;
				border-left:none !important;
				border-right: 10px solid #38AAE1;
				max-width: 90% !important;
				width: auto !important;
				min-width: 56% !important;
			}
		}
	}
	&.tab-one-section {
	}
	&.tab-two-section {
		.waypoint{
			padding-left:0 !important;
			padding-right:0 !important;
		}
		.image-side{

		}
	}
	&.text-with-image-section-m{
		.title{
			margin-bottom: 20px !important;
		}
		.col-md-5{
			padding-top:0 !important;
		}
		blockquote{
			font-size:20px !important;
			line-height: 1.1;
		}
	}
	&.info-side {
		background-color:transparent !important;
		.slider{
			padding-top:6px;
			overflow: hidden;
			background-color:transparent;
			.slick-list,
			.slick-track{
				overflow: visible;;
			}
			.slick-slide{
				background-color:#ffffff
			}
		}
	}
}

.matchHeight{
	margin-bottom: 0;
}

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
	//min-height: calc(100vh - 120px - 366px);
}

.fp-tableCell,
.wrapper{
	margin: 0 auto;
	width: 95.834%;
	padding-bottom: 0;
	max-width: $base-width;
	position: relative;
	clear: both;

	@media (max-width: $b2){
		width: 87%;
	}

	&.mob-wide{

		@media (max-width: $b3){
			width: 100%;
		}
	}
}

.mob-wrapper{

	@media (max-width: $b3){
		margin-left: auto;
		margin-right: auto;
		width: 81%;
		padding-bottom: 0;
		max-width: $base-width;
		position: relative;
		clear: both;
		float: none;
	}
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

.breadcrumb-con{
	@include font-size(14);
	line-height: 1.4em;
	color: #333;
	font-weight: 400;
	display: flex;
	justify-content: flex-start;
	padding-top: 15px;
	padding-bottom: 3px;
	font-family: $alt-font;

	*{
		color: #333;
		font-family: $alt-font;
	}

	a{

		&:hover,
		&:focus{
			color: $blue;
			text-decoration: none;
		}
	}
}

#breadcrumbs{

	*{
		color: #333;
		font-family: $alt-font;
	}

	.search &{

		> span{

			&:after{
				content: " '";
			}
		}
	}

	a{

		&:hover,
		&:focus{
			color: $blue;
			text-decoration: none;
		}
	}
}

//
//!! BREADCRUM END
//

//.pagination-simple{
//	padding: 7px 0 2px;
//
//	a{
//		color: $blue;
//		font-weight: 700;
//		padding-top: 1px;
//		@include inline;
//		@include font-size(20);
//		@include line-height(20,33);
//		letter-spacing: .03em;
//		margin-bottom: 1em;
//		text-transform: uppercase;
//
//		@media (max-width: $b2){
//			@include font-size(20 / $divider);
//		}
//
//		&:hover,
//		&:focus{
//			color: $blue;
//		}
//
//		&.back-to,
//		&[rel="next"]{
//			float: left;
//		}
//
//		&[rel="prev"]{
//			float: right;
//		}
//	}
//}

//simple pagination

//complex pagination

//.pagination-con{
//	display: flex;
//	justify-content: space-between;
//
//	@media (max-width: $b2){
//		justify-content: center;
//		flex-direction: column;
//		align-items: center;
//
//		.pagination.pagination.pagination{
//			margin-bottom: 20px;
//		}
//	}
//}
//
//.pagination-under{
//
//	.col-12{
//		display: flex;
//		justify-content: center;
//		align-items: center;
//
//		.pagination.pagination{
//			margin-bottom: 50px;
//		}
//	}
//}
//

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	padding-top: 71px;
	display: flex;
	justify-content: space-between;
	//width: 100%;
	align-items: flex-start;
	flex: 0 0 0;
	max-width: 100%;

	@media (max-width: $b3){
		padding-top: 10px;
	}

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 5.5px;
		@include font-size(16);
		line-height: 1.2em;

		&:not(.prev):not(.next){
//			width: 54px;
//			height: 54px;
//
//			@media (max-width: $b3){
//				width: 32px;
//				height: 32px;
//			}

			&.active{
				pointer-events: none;

				a{
					color: $white;
				}
			}

			a{
				@include font-size(16);
				line-height: 1.2em;
				display: block;
				color: $green;
				font-weight: 300;
				padding: 0;

				&:hover,
				&:focus{
					text-decoration: none;
					color: $white;
				}
			}

			&.ellipse{
				color: $green;
			}
		}

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}

		&.next,
		&.prev{

			a{
				@include font-size(16);
				line-height: 1.2em;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $green;
				text-decoration: underline;

				&:hover,
				&:focus{
					text-decoration: none;
				}
			}

		}

		&.next{

			a{
				margin-left: 3.3em;

				@media (max-width: $b3){
					margin-left: 1.5em;
				}

				&:hover,
				&:focus{
					text-decoration: none;

					&:after{
						transform: translateX(.421em);
					}
				}

				&:after{
					content: '';
					width: 1.4533em;
					height: .823em;
					background-size: 1.4533em .823em;
					background-repeat: no-repeat;
					margin-top: .21em;
					margin-left: 3.3em;
					transform: translateX(00px);
					transition: $time;
					background-image: url(../images/arrow-blue.svg);

					@media (max-width: $b3){
						margin-left: 1.5em;
					}
				}
			}
		}

		&.prev{

			a{
				margin-right: 3.3em;

				@media (max-width: $b3){
					margin-right: 1.5em;
				}

				&:hover,
				&:focus{
					text-decoration: none;

					&:before{
						transform: translateX(-.421em);
					}
				}

				&:before{
					content: '';
					width: 1.4533em;
					height: .823em;
					background-size: 1.4533em .823em;
					background-repeat: no-repeat;
					margin-top: .23em;
					margin-right: 3.3em;
					transform: translateX(00px);
					transition: $time;
					background-image: url(../images/arrow-blue-left.svg);

					@media (max-width: $b3){
						margin-right: 1.5em;
					}
				}
			}
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		26;
$social-addition:	0;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;
$behance:		#131418;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $colour;
		text-decoration: none;
		background-image: none;
		padding: 0;
		font-weight: 100;
		margin: 0 4.5px;
		background-color: transparent;

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;

			&:hover,
			&:focus{
				color: white;
			}
		}

		&.facebook{

			&:hover,
			&:focus{
				color: $facebook!important;
			}
		}
//
//
//		&.behance{
//
//			&:hover,
//			&:focus{
//				color: $behance!important;
//			}
//		}
//
		&.twitter{

			&:hover,
			&:focus{
				color: $twitter!important;
			}
		}

		&.linkedin{

			&:hover,
			&:focus{
				color: $linkedin!important;
			}
		}
//
//		&.pinterest{
//
//			&:hover,
//			&:focus{
//				color: $pinterest!important;
//			}
//		}
//
//		&.rss{
//
//			&:hover,
//			&:focus{
//				color: $rss!important;
//			}
//		}
//
//		&.slideshare{
//
//			&:hover,
//			&:focus{
//				color: $slideshare!important;
//			}
//		}
//
//		&.google-plus{
//
//			&:hover,
//			&:focus{
//				color: $google-plus!important;
//			}
//		}
//
		&.instagram{

			&:hover,
			&:focus{

				i{
					color: $instagram!important;
					background: -webkit-linear-gradient(45deg, rgba(255,204,101,1) 0%, rgba(218,34,108,1) 51%, rgba(73,103,211,1) 100%)!important;
					-webkit-background-clip: text!important;
					-webkit-text-fill-color: transparent!important;
				}
			}
		}
//
//		&.reddit{
//
//			&:hover,
//			&:focus{
//				color: $reddit!important;
//			}
//		}
//
//		&.youtube{
//
//			&:hover,
//			&:focus{
//				color: $youtube!important;
//			}
//		}
//
//		&.mail{
//
//			&:hover,
//			&:focus{
//				color: $mail!important;
//			}
//		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .5s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

a{

	.hidden{
		height: 0;
		width: 0;
		overflow: hidden;
		display: block;
		opacity: 0;
		visibility: visible;
	}
}

//
//!!PAGE SPECIFIC
//

.hero-section{

	.search-side{

		@media (min-width: $b3 + 1){
			width: 235px;
			flex: 0 0 235px;
			max-width: 235px;
			border-right: 1px solid white;
		}
	}

	.text{
		height: 382px;
		padding: 51px 50px;

		@media (max-width: $b3){
			padding: 20px 20px 60px 20px;
			height: auto;
		}

		@media (min-width: $b3 + 1){
			width: calc(100% - 235px);
			flex: 0 0 calc(100% - 235px);
			max-width: calc(100% - 235px);
		}

		h1{
			text-shadow: 0 0 6px #000;
		}

		*{
			color: white;
		}
	}
}

.search-side{
	background-color: $blue;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.search-con{
		padding-top: 8px;
		padding-bottom: 13px;

		span{
			color: $yellow;
			@include font-size(16);
			margin: 3px 0;
			display: block;
		}

		button{
			width: auto;
		}
	}

	.file-link{
		width: calc(100% + 18px + 18px);
		max-width: calc(100% + 18px + 18px);
		margin-left: -18px;
		display: block;
		border-top: 1px solid white;
	}

	img{
		width: 100%;

		@media (max-width: $b3){
			display: none;
		}
	}
}

.categories-section{
	padding-top: 29px;
}

.c-item{
	background-color: white;
	padding-left: 0;
	padding-right: 0;
	display: flex;

	.inner{
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;


			&:hover,
			&:focus{

				~ .text-bottom{

					.btn{
						color: $yellow!important;
						background-color: $blue;
					}
				}
			}
		}
	}

	img{
		width: 100%;
		margin-left: -18px;
	}

	.img-con{
		width: 100%;
		padding-top: 63%;
	}

	.text-bottom{
		padding: 20px 20px 55px;
		flex-grow: 1;

		h3{
			color: black;
		}

		.btn{
			position: absolute;
			left: 20px;
			bottom: 20px;
		}
	}
}

.speak-with-section{
	margin-bottom: 50px;

	@media (max-width: $b3){
		margin-bottom: 0;
	}

	.col-12{
		background-color: $blue;
		background: linear-gradient(0deg, rgb(8, 45, 117) 3%, rgb(2, 98, 167) 100%);
		padding-top: 4px;
		text-align: center;

		@media (min-width: $b2 + 1){
			padding-left: 150px;
			padding-right: 150px;
		}

		.img-con{
			position: absolute;
			top: 0;
			bottom: 0;
			width: 140px;

			@media (max-width: $b2){
				display: none;
			}

			&.left{
				left: 0;
			}

			&.right{
				right: 0;
			}
		}

		h2{
			@include font-size(26);
			@include line-height(26,28);
			margin-bottom: 7px;
		}

		p{
			margin-bottom: 12px;
		}

		*{
			color: white;
			font-weight: 400;

			em{
				color: $yellow;
			}
		}

		.points{
			display: inline-flex;
			justify-content: center;
			align-items: center;
			border-bottom: solid 1px $blue-light;

			@media (max-width: $b2){
				border-bottom: none;
			}

			@media (max-width: $b2){
				flex-wrap: wrap;
			}

			.point{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 16px 14px;

				@media (max-width: $b3){
					margin: 0 5px 7px;
				}

				.number{
					text-align: center;
					width: 34px;
					line-height: 34px;
					border-radius: 34px;
					border: solid 1px $blue-light;
					margin-right: 10px;

					@media (max-width: $b3){
						width: 30px;
						line-height: 30px;
					}
				}
			}
		}

		span{
			display: block;
			text-align: center;
			color: $yellow;
			@include font-size(16);

			@media (max-width: $b2){
				display: none;
			}
		}
	}
}

.top-section{

	.error404 &{

		.title{
			padding-top: 10px;
		}
	}

	.container{
		background-color: white;
		padding-right: 35px;
		padding-left: 35px;

		@media (max-width: $b3){
			padding-right: 18px;
			padding-left: 18px;
		}
	}

	+ section{

		&:last-child{
			padding-bottom: 50px;

			@media (max-width: $b3){
				padding-bottom: 25px;
			}
		}

		.container{
			background-color: white;
			padding-right: 35px;
			padding-left: 35px;

			@media (max-width: $b3){
				padding-right: 18px;
				padding-left: 18px;
			}
		}
	}

	+ .woocommerce{
		max-width: 1100px;
		width: calc(100% - 18px - 18px);
		margin: 0 auto;
		background-color: white;
		padding-right: 35px;
		padding-left: 35px;
		overflow: hidden;

		@media (max-width: $b3){
			padding-right: 18px;
			padding-left: 18px;
		}

		@media (max-width: $b4){
			width: calc(100% - 17px - 17px);
		}

		&:last-child{
			margin-bottom: 50px;
		}

		.col2-set{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.col-1,
			.col-2{
				padding-left: 0;
				padding-right: 0;
				width: 50%;
				flex: 0 0 50%;
				max-width: 50%;

				@media (max-width: $b3){
					width: 100%;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}

			@media (min-width: $b3 + 1){
				.col-1{
					padding-right: 18px;
				}

				.col-2{
					padding-left: 18px;
				}
			}
		}
	}

	.title{

		h1{
			@include font-size(30);
			font-weight: 200;
			color: $blue;
			border-bottom: 1px solid #ddd;
			padding-bottom: 19px;
			margin-bottom: 10px;
		}
	}
}

.woocommerce-MyAccount-content{

	.u-column1{

		&.col-1{
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.u-column1{

	&.col-1{
		width: 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.contact-section{

	iframe{
		width: 100%;
	}

	.container{
		padding-bottom: 20px;
	}

	p{
		font-family: $alt-font;
	}

	li{
		margin-bottom: 3px;
	}
}

.content-section{

	.error404 &{

		dt{
			strong{
				color: black;
				font-weight: 700;
			}
		}
	}

	dd{
		margin-left: 21px;
	}

	.container{
		padding-bottom: 20px;
	}

	h5{
		color: black;
		margin-bottom: 19px;
	}

	p{
		font-family: $alt-font;

		strong{
			font-family: $main-font;
			margin-bottom: 6px;
			display: inline-block;
		}
	}

	&.larger-text{

		p,
		li{
			@include font-size(16);
			color: #000000;
		}

		li{
			margin-bottom: 3px;
		}
	}
}

.links a{

	&.basket.basket{
		transition: $time;

		&.full{
			background-color: $green;
			color: white;

			i{
				filter: brightness(11111);
			}

			*{
				color: white;
			}

			&:hover,
			&:focus{
				background-color: darken($green, 5%);
				color: white;

				i{
					filter: brightness(11111);
				}

				*{
					color: white;
				}
			}
		}

		*{
			transition: $time;
		}

		i{
			transition: $time;
		}

		&:hover,
		&:focus{
			background-color: $green;
			color: white;

			i{
				filter: brightness(11111);
			}

			*{
				color: white;
			}
		}
	}
}

.single-product{

	.woocommerce-message{

		.button.wc-forward.wc-forward.wc-forward.wc-forward.wc-forward{
			background-color: $green;
			color: white!important;

			&:hover,
			&:focus{
				background-color: darken($green, 10%);
				color: white!important;
			}
		}
	}
}

[name="add-to-cart"]{
	opacity: .25;

	.loaded &{
		opacity: 1;
	}
}

.woocommerce.woocommerce.woocommerce,
.woocommerce-page.woocommerce-page.woocommerce-page{

	.form-row{
		margin-bottom: 0;
		padding: 0;

		input.input-text,
		textarea{
			margin-bottom: $input-bottom-margin;
		}
	}

	form .form-row-first,
	form .form-row-last,
	form .form-row-first,
	form .form-row-last{

		@media (max-width: $b4){
			width: 100%;
		}
	}

	.woocommerce-form-login__rememberme{
		margin-bottom: $input-bottom-margin;
	}

	[type="submit"]{
		margin-bottom: $input-bottom-margin;
	}

	form .form-row .required{
		color: $red;
	}

	form .show-password-input.display-password::after,
	form .show-password-input.display-password::after{
		color: $blue;
	}

	form.checkout_coupon,
	form.login,
	form.register{
		border: none;
		padding: 0;
		margin-top: 0;
	}

	form .form-row input.input-text,
	form .form-row textarea{
		line-height: 1.3em;
	}

	.select2-dropdown{
		max-width: none;
		border: 1px solid #ddd;
		border: 1px solid $grey;
		border-top: none;

		.select2-results__option[data-selected=true]{
			background-color: #ddd;
		}

		.select2-results__option--highlighted[data-selected]{
			color: $yellow;
			background-color: $blue;
			transition: $time;
		}

		.select2-search__field{
			margin-bottom: 0;
			border: 1px solid #ddd;
		}
	}

	form .select2-container{
		margin-bottom: $input-bottom-margin;
		height: 32px;
		border: 1px solid #ddd;

		&--open{
			border: 1px solid $grey;
			border-bottom: none;

			.select2-selection__arrow.select2-selection__arrow{
				background-image: url(../images/select-icon-up.svg);
			}
		}

		&--focus{
			border: 1px solid $grey;
		}

		.select2-selection--single.select2-selection--single{
			border: none;
			background-color: transparent;
		}

		.select2-selection--single{
			height: 32px;
			border: 1px solid #ddd;
			background-color: white;
			border-radius: 0;
			line-height: 32px;
		}

		.select2-selection__rendered{
			height: 31px;
			line-height: 31px;
			padding-right: 20px;

		}

		.select2-selection__arrow{
			height: 30px;
			background-image: url(../images/select-icon.svg);
			background-repeat: no-repeat;
			background-position: 100% 50%;

			b{
				display: none;
			}
		}
	}

	#respond input#submit,
	a.button,
	button.button,
	input.button{
		@extend .btn;
	}

	legend{
		padding-left: 10px;
		padding-right: 10px;
		margin-left: -10px;
	}

	fieldset{
		margin: 0 0 20px;
		padding: 18px 18px;
		border-color: $blue;
		border-style: solid;
		border-width: 1px;
	}

	.woocommerce-Address-title{
		display: flex;
		position: relative;
		margin-bottom: 12px;

		h3{
			width: 100%;
			padding-right: 50px;
			margin-bottom: 0;
		}

		.edit{
			position: absolute;
			bottom: .15em;
			right: 0;

			&:hover,
			&:focus{
				color: $blue;
			}
		}
	}

	a.remove{
		color: $red!important;
		font-weight: 400;
		line-height: 16px;

		&:hover,
		&:focus{
			background-color: $red;
			color: $white!important;
		}
	}

	.coupon.coupon.coupon{
		display: flex;
		align-items: center;

		input,
		.button{
			margin-bottom: 10px;
		}

		input{
			min-width: 150px;

			@media (max-width: $b3){
				min-width: 0;
			}
		}

		.button{
			padding: 6.5px 20px;
			width: auto;
		}
	}

	table.shop_table td{

		@media (max-width: $b3){
			padding: 6px 8px;
		}
	}

	.cart_totals{

		table{

			td{
				width: 95px!important;

				@media (max-width: $b3 + 1){
					width: 100%!important;
				}
			}

			.order-total .woocommerce-Price-amount{
				font-weight: 700;
			}
		}
	}

	table{

		.product-subtotal{
			font-weight: 700;
		}

		&.woocommerce-cart-form__contents{


			@media (max-width: $b3){

				tbody{

					tr:first-of-type{

						td:first-of-type{
							border-top: none!important;
						}
					}
				}
			}
		}

		.actions{

			@media (max-width: $b3){
				padding-left: 0;
				padding-right: 0;
			}

			.button{
				width: auto;
				padding: 6.5px 20px;

				&:disabled{

					&:hover,
					&:focus{
						background-color: $yellow;
						color: $blue!important;
					}
				}
			}
		}

		.cart_item{

			@media (max-width: $b3){
				padding: 0;
			}
		}

		.product-remove{

			@media (max-width: $b3){
				padding-bottom: 0;
			}
		}

		.product-thumbnail + .product-name{

			@media (max-width: $b3){
				border-top: none!important;
			}
		}

		img{
			width: 75px;
		}

		.product-remove{
			width: 45px !important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-thumbnail,
		.product-price,
		.product-quantity,
		.product-subtotal{
			width: 95px !important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-quantity{

			@media (max-width: $b3 + 1){
				display: flex;
				align-items: center;
				justify-content: space-between;

			}
		}

		input{
			border-bottom: 1px solid #ddd!important;

			&:focus{
				border-bottom: 1px solid $grey!important;
			}
		}

		.screen-reader-text{
			height: 0;
			width: 0;
		}

		&.shop_table{

			td{
				border-top: 1px solid #ddd;
			}
		}

		.product-name{

			a{
				color: $colour;
				text-decoration: none;

				&:hover,
				&:focus{
					color: $blue;
				}
			}
		}
	}

	.cart-collaterals{

		h2{
			font-weight: 200;
			color: $blue;
		}
	}

	.shop_table{

		td:last-of-type{
			width: 95px!important;
			width: 210px!important;

			label:before{
				margin-top: 2px;
			}

			@media (max-width: $b3 + 1){
				width: 100%!important;

				&.product-total{
					width: 95px!important;
					width: 210px!important;

					label:before{
						margin-top: 2px;
					}
				}
			}
		}
	}

	.woocommerce ul#shipping_method li{
		padding-left: 0;
	}

	.woocommerce-checkout-review-order-table{

		.cart-subtotal,
		.order-total{

			td:last-of-type{

				@media (max-width: $b3 + 1){
					width: 95px!important;

				}
			}
		}
	}

	.woocommerce-checkout{

		#createaccount{

			+ span:last-of-type{
				margin-bottom: 18px;
			}
		}

		#terms{

			+ span{

				&:after{
					content: '*';
					color: $red;
					font-weight: 700;
					margin-left: .25em;
				}
			}

			~ span.required{
				display: none;
			}
		}

		h3{
			color: $blue;
			border-bottom: 1px solid #ddd;
			padding-bottom: 10px;
		}

		.input-checkbox{
			display: none;
		}

		#order_review_heading{
			padding-top: 20px;
		}
	}

	form .form-row.woocommerce-validated .select2-container,
	form .form-row.woocommerce-validated input.input-text,
	form .form-row.woocommerce-validated select{
		border-color: $green;
	}

	form .form-row.woocommerce-invalid .select2-container,
	form .form-row.woocommerce-invalid input.input-text,
	form .form-row.woocommerce-invalid select{
		border-color: $red;
	}

	form .form-row.woocommerce-invalid label{
		color: $red;
	}

	.woocommerce-checkout-payment{
		background-color: transparent!important;

		.input-checkbox{
			display: none;
		}

		.place-order{
			padding: 20px 0 0!important;
		}

		.woocommerce-terms-and-conditions-wrapper{
			margin-bottom: 20px;
		}
	}

	tabel.order_details tbody{

		@media (max-width: $b3){

			.product-name{
				width: 100%!important;
			}
		}
	}

	.woocommerce-order{

		h2{
			font-weight: 200;
			color: $blue;
		}
	}

	.woocommerce-customer-details address{
		border: 1px solid $blue;
		border-radius: 0;
		line-height: 1.4em;

		p{
			line-height: 1.5em;
		}
	}
}

.woocommerce-checkout{

	.woocommerce &{
		padding-bottom: 30px;
	}
}

.woocommerce-error.woocommerce-error,
.woocommerce-info.woocommerce-info,
.woocommerce-message.woocommerce-message{
	padding: 10px 10px 10px 3.5em;
	border-top-width: 1px;
	margin-top: 4px;
	margin-bottom: 20px;
	line-height: 35px;
	background-color: white;

	&:before{
		top: .8em;
	}

	@media (max-width: $b3){

		.button{
			width: 100%;
		}
	}
}

.woocommerce-message.woocommerce-message{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-error.woocommerce-error{
	border-top-color: $red;

	&:before{
		color: $red;
	}
}

.woocommerce-info.woocommerce-info{
	border-top-color: $blue;

	&:before{
		color: $blue;
	}
}

.woocommerce-view-order{

	@media (max-width: $b3){

		.product-name.product-name.product-name.product-name.product-name{
			width: 100%!important;
		}
	}
}

.page-id-8 {

	&.woocommerce-edit-address{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 2px;
		}
	}


	&.woocommerce-edit-account{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 0;
		}
	}

	.woocommerce{
		display: flex;
		flex-wrap: wrap;

		.woocommerce-MyAccount-navigation{
			width: 200px;

			@media (max-width: $b3){
				width: 100%;
			}

			ul{

				@media (max-width: $b3){
					padding-bottom: 10px;
					border-bottom: 1px solid #ddd;
					display: flex;
					flex-direction: column;
				}

				&.open{

					li.is-active{
						background-image: url(../images/arrow-up.svg);
					}

					li:not(.is-active){
						display: block;
					}
				}

			}

			li{
				padding-left: 0;
				line-height: 0;

				@media (max-width: $b3){

					&:nth-of-type(1){
						order: 2;
					}

					&:nth-of-type(2){
						order: 3;
					}

					&:nth-of-type(3){
						order: 4;
					}

					&:nth-of-type(4){
						order: 5;
					}

					&:nth-of-type(5){
						order: 6;
					}

					&:nth-of-type(6){
						order: 7;
					}

					&:nth-of-type(7){
						order: 8;
					}

					&.is-active{
						padding-right: 30px;
						background-image: url(../images/arrow-down.svg);
						background-repeat: no-repeat;
						background-position: 100% 11px;
						cursor: pointer;

						a{
							pointer-events: none;
						}
					}

					&:not(.is-active){
						display: none;
					}

				}

				&.is-active{

					@media (max-width: $b3){
						order: 1;
					}

					a{
						padding-left: 9px;
						background-position: 0 .65em;
					}
				}

				a{
					text-decoration: none;
					line-height: 2em;
					display: inline-block;
					background-image: url(../images/arrow-right-blue.svg);
					background-repeat: no-repeat;
					overflow: hidden;
					background-position: -10px .65em;


					&:hover,
					&:focus{
						padding-left: 9px;
						background-position: 0 .65em;
					}
				}
			}

			+ .woocommerce-MyAccount-content{
				width: calc(100% - 200px);
				padding-top: 5px;

				@media (max-width: $b3){
					width: 100%;
				}
			}
		}
	}
}

a.no-show span{
	opacity: 0;
	width: 0;
	height: 0;
	@include font-size(0);
	pointer-events: none;
	overflow: hidden;

}

.products-section{
	padding-bottom: 50px;

	.search-con{
		padding-left: 20px;
		padding-right: 20px;
	}

	> .container > .row{
		background-color: white;
	}

	.container-fluid{
		padding-left: 0;
		padding-right: 0;
	}

	.filter-side{
		padding-left: 0;
		padding-right: 0;

		@media (min-width: $b3 + 1){
			width: 235px;
			flex: 0 0 235px;
			max-width: 235px;
		}

		.search-con{


		}

		.file-link{
			margin-left: 0;
			width: 100%;
			max-width: 100%;
		}
	}

	.product-single-side{

		@media (min-width: $b3 + 1){
			padding-left: 36px;
			padding-right: 36px;
			width: calc(100% - 235px);
			flex: 0 0 calc(100% - 235px);
			max-width: calc(100% - 235px);
		}
	}

	.product-side{

		@media (min-width: $b3 + 1){
			padding-left: 36px;
			padding-right: 0;
			width: calc(100% - 235px);
			flex: 0 0 calc(100% - 235px);
			max-width: calc(100% - 235px);
		}

		#breadcrumbs{
			padding-top: 14px;
			padding-bottom: 5px;
		}

		.title{
			padding-bottom: 1px;

			&:not(.simple-title){

				h1{
					background-color: $grey-darker;
					padding: 25px 25px 12px;
					@include font-size(30);
					color: white;
					margin-bottom: 0;
				}
			}

			&.simple-title{

				h1{
					@include font-size(30);
					color: $blue;
				}

				~ .call-con{
					display: none;
				}
			}


		}

		.search-title{

			h1{
				@include font-size(30);
				color: $blue;
				margin-bottom: 0;
				border-bottom: 1px solid #ddd;
				padding-bottom: 18px;
				margin-top: -2px;
				margin-bottom: 5px;
			}
		}

		.img-con{

			img{
				width: 100%;
			}
		}

		.call{
			background-color: $blue;
			padding-top: 4px;
			text-align: center;
			margin-bottom: 15px;
			padding-left: 5px;
			padding-right: 5px;

			h2{
				@include font-size(22);
				font-weight: 200;
				line-height: 1.25em;
				margin-bottom: -3px;
				position: relative;

				&:before{
					content: '';
					background-image: url(../images/phone.svg);
					background-repeat: no-repeat;
					width: 18px;
					height: 19px;
					background-size: 18px auto;
					background-position: 50% 50%;
					display: inline-block;
					margin-right: 7px;
					margin-top: 5px;
					margin-bottom: -2px;
				}


				span{
					@include font-size(12);
				}
			}

			*{
				color: white;
				font-weight: 400;

				em{
					color: $yellow;
				}
			}

			.points{
				display: inline-flex;
				justify-content: center;
				align-items: center;

				@media (max-width: $b2){
					flex-wrap: wrap;
				}

				.point{
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 16px 9px;

					.number{
						text-align: center;
						width: 24px;
						line-height: 22px;
						border-radius: 24px;
						border: solid 1px $blue-light;
						margin-right: 10px;
					}
				}
			}
		}

		.row.bottom{
			margin-bottom: 117px;
		}

		.description-con{

			p{
				font-family: $alt-font;
				margin-bottom: 14px;

				&:last-of-type{
					margin-bottom: 8px;
				}

				strong{
					//@include font-size(16);
				}
			}
		}
	}

	.row.products,
	.row.products-top{

		@media (min-width: $b3 + 1){
			.description-con,
			.filter{
				padding-right: 36px;
			}

			.p-item:nth-of-type(3n + 5){
				padding-right: 36px;
			}
		}
	}

	.filter{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 36px;

		@media (max-width: $b3){
			flex-direction: column;
			align-items: flex-start;
		}

		.items{
			@include font-size(12);
			font-weight: 400;
			color: black;

			@media (max-width: $b3){
				margin-bottom: 6px;
			}
		}

		.select-con{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			min-width: 150px;

			label{
				@include font-size(12);
				font-weight: 400;
				color: black;
				margin-right: 12px;

			}

			select{
				@include font-size(12);
				height: 27px;
				line-height: 25px;
				width: auto;
				margin: 0;
				background-color: #E5E5E5;
				border-color: #CCCCCC;
				background-image: url(../images/select-icon-grey.svg);
				padding-right: 34px;
				min-width: 200px;
			}
		}
	}


	.pagination_row{
		padding-bottom: 42px;
		@include font-size(12);
		font-family: $alt-font;
		color: black;

		&.none{
			padding-bottom: 0;
		}

		span,
		a{
			@include font-size(12);
			font-family: $alt-font;
			color: black;
			margin: 0 4px;

			span{
				margin: 0;
				padding: 0;
			}
		}
	}
}

.p-item{
	margin-bottom: 15px;

	.inner{
		position: relative;
		width: 100%;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus{

				~ .info{

					.btn{
						color: $yellow!important;
						background-color: $blue;
					}
				}
			}
		}

		h4{
			font-weight: 400;
			font-family: $alt-font;
			margin-bottom: 11px;
		}

		.price{
			font-weight: 400;
			font-family: $alt-font;
			color: black;
			@include font-size(14);

			.poa{
				font-weight: 700;
				font-family: $alt-font;

				span{
					font-weight: 400;
					font-family: $main-font;
				}
			}

			.reg.sale{
				opacity: .25;
				text-decoration: line-through;
				color: black;
			}

			.sale{
				color: $blue;
			}

			bdi{
				font-weight: 700;
			}
		}

		.btn-con{
			padding-top: 11px;
		}
	}

	.img-con{
		margin: 0 0 20px;
		width: 141px;
		height: 141px;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 141px auto;
	}
}

.archive .product-side{

	@media (max-width: $b3){

		.col-12{
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.woocommerce.single-product{

	.woocommerce-notices-wrapper{
		width: calc(100% - 18px - 18px);

		@media (max-width: $b4){
			width: calc(100% - 17px - 17px);
		}
	}

	.woocommerce-error.woocommerce-error,
	.woocommerce-info.woocommerce-info,
	.woocommerce-message.woocommerce-message{
		margin-bottom: 0;
		@include font-size(14);

		li{
			line-height: 35px;
		}
	}

	.woocommerce-notices-wrapper{
		max-width: $base-width + $grid-gutter-width;
		margin: 0 auto;
		background-color: white;
		background-color: white;

		.woocommerce-message{
			margin-top: 20px;
			margin-bottom: 0;
			box-shadow: 0 -15px 0 5px white;
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.product-single-side{
		padding-top: 15px;
		padding-bottom: 28px;

		.sku_wrapper{
			display: none;
		}

		.sku{
			display: none;
		}

		.category{
			@include font-size(30);
			font-weight: 200;
			color: $blue;
			padding-top: 11px;
			padding-bottom: 19px;
			line-height: 1em;

			a{
				font-weight: 200;
				color: $blue;
				text-decoration: none;
			}
		}

		.onsale{
			display: none;
		}

		.btn{
			width: auto;
		}

		.back{
			color: black;
			font-family: $alt-font;
		}

		h3{
			font-weight: 600;
			color: black;
			margin-top: 6px;
			margin-bottom: 4px;
		}

		h1{
			color: black;
			font-size: 20px;
			line-height: 22px;
			font-weight: 700;
		}

		.stock{
			font-weight: 700;
			margin-bottom: 10px;

			&.out{
				color: $red;
			}
		}

		.product_meta{
			display: none;
		}

		.tec{
			font-family: $alt-font;
			margin-bottom: 12px;
		}

		.tec-button{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: $blue;
			color: white;
			padding: 3.5px 12px;
			letter-spacing: .05em;
			text-decoration: none;
			margin-bottom: 12px;

			&:hover,
			&:focus{
				background-color: $yellow;
				color: $blue;

				svg path{
					fill: $blue;
				}
			}

			svg{
				margin-left: 9px;
				width: 18.72px;
				height: 19px;
				padding: 1px;

				path{
					fill: white;
					transition: $time;
				}
			}
		}

		.poa-text{
			padding-top: 29px;
			padding-bottom: 30px;

			p{
				font-family: $alt-font;

				strong{
					@include font-size(16);
					line-height: 1.2em;
				}
			}

			+ .cart{
				display: none;
			}
		}

		.product_meta{

			p{
				font-family: $alt-font;
			}
		}

		.price{
			color: black;
			margin-bottom: 12px;

			&.variable{
				display: none;
			}

			.poa{
				color: $colour;
				font-weight: 700;
			}

			.reg.sale{
				opacity: .25;
				text-decoration: line-through;
				color: black;
			}

			.sale{
				color: $blue;

				&:last-of-type{

					bdi{
						font-weight: 700;
					}
				}
			}

			.reg:last-of-type{

				bdi{
					font-weight: 700;
				}
			}
		}

		.variations_form{
			border: 1px solid #ddd;
			background-color: #f6f6f6;
			padding: 6px 10px;
			padding: 0;
			margin-top: 12px;
			margin-bottom: 28px;

			.button{
				margin-bottom: 10px;

				&.disabled{
					background-color: $yellow;
					color: $blue!important;
					cursor: not-allowed;
				}
			}

			td{
				border-top: none!important;
			}

			.variations{
				padding: 6px 10px;
				margin: 0!important;
				display: block;

				tbody{
					display: block;
					width: 100%;
				}

				.reset_variations{
					text-decoration: none;

					&:hover,
					&:focus{
						text-decoration: underline;
					}
				}
			}

			tr{
				display: block;
				padding: 0;

				.label,
				.value{
					display: block;
					padding: 0;
				}

				label{
					font-weight: 700;
					color: black;
					margin-bottom: 4px;

					&:before{
						content: '*';
						margin-right: .1em;
					}
				}

				select{
					border-bottom: 1px solid #ddd!important;
					margin-bottom: 5px;

					&:focus{
						border-bottom: 1px solid $grey!important;
					}
				}
			}

			.single_variation_wrap{
				border-top: 1px solid #ddd;
				background-color: white;
				padding: 20px 10px 0;

				.single_variation{
					margin-bottom: 12px;
				}
			}

		}
	}

	.woocommerce-tabs{
		display: none;
	}

	.product-slide,
	.images_slider_large{
		position: relative;
		margin-top: 7px;
		margin-bottom: 5px;

		&.hide{
			display: none!important;
		}

		&:hover,
		&:focus{

			&:after{
				opacity: 0;
			}
		}

		&:after{
			content: 'Zoom';
			position: absolute;
			right: 10px;
			bottom: 10px;;
			background-image: url(../images/zoom.svg);
			background-repeat: no-repeat;
			padding-left: 15px;
			background-position: 0 50%;
			@include font-size(12);
			color: #444444;
			pointer-events: none;
			line-height: 13px;
			transition: $time;
		}
	}

	.product-nav{

		.slick-track{
			margin: 0;
		}
	}

	.product-nav {

		.slick-track{
			display: flex;
			align-items: center;
		}

		.slick-slide{
			float: none;
			cursor: pointer;
		}
	}

	.product-slide{

		@media (max-width: $b3){
			width: 360px;
			max-width: 100%;
		}

		@media (min-width: $b3 + 1){

			> img{
				width: 100%;
			}
		}
	}

	.product-slide .slick-slide{
		position: relative;
		overflow: hidden;

		span{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		> img{
			margin: 0 auto;

			@media (max-width: $b3){
				width: 100%;
			}
		}

		img{

			&:hover{
				cursor: auto;
				cursor: url(../images/zoom-big.svg), auto;
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

				&:hover{
					cursor: auto;
				}
			}
		}
	}

	.call{
		background-color: $blue;
		padding-top: 4px;
		text-align: center;
		padding-left: 16px;
		padding-right: 16px;

		h2{
			@include font-size(22);
			font-weight: 200;
			line-height: 1.25em;
			margin-bottom: -3px;
			position: relative;

			&:before{
				content: '';
				background-image: url(../images/phone.svg);
				background-repeat: no-repeat;
				width: 18px;
				height: 19px;
				background-size: 18px auto;
				background-position: 50% 50%;
				display: inline-block;
				margin-right: 7px;
				margin-top: 5px;
				margin-bottom: -2px;
			}

			span:not(.small){
				white-space: nowrap;
				font-weight: 200;

				@media (max-width: $b2){
					white-space: normal;
				}
			}

			span.small{
				@include font-size(12);
				display: block;
			}
		}

		*{
			color: white;
			font-weight: 400;

			em{
				color: $yellow;
			}
		}

		.points{
			display: inline-flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			.point{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 10px 9px;

				.number{
					text-align: center;
					width: 24px;
					line-height: 22px;
					border-radius: 24px;
					border: solid 1px $blue-light;
					margin-right: 10px;
				}
			}
		}
	}

	.products{
		margin-top: 35px;

		ul.products{
			margin-top: 0;
			display: flex;
			flex-wrap: wrap;

			.p-item{
				width: 50%;
				padding-right: 10px;

				&:nth-last-of-type(1n + 3){
					display: none;
				}
			}


		}

		h2{
			background-color: $blue;
			color: white;
			margin-bottom: 13px;
			padding: 3px 7px;
			@include font-size(14);
			font-weight: 400;
			text-transform: uppercase;
		}
	}
}

.woocommerce-breadcrumb.woocommerce-breadcrumb.woocommerce-breadcrumb{
	color: #333;
	font-family: $alt-font;
	font-weight: 400;
	@include font-size(0);
	display: flex;
	align-items: center;
	flex-wrap: wrap;


	*{
		color: #333;
		font-family: $alt-font;
		font-weight: 400;
		@include font-size(14);
	}

	a{
		position: relative;
		margin-right: 13px;

		&:after{
			content: ' > ';
			margin-right: .2em;
			color: #333!important;
			pointer-events: none;
			position: absolute;
			right: -13px;
		}

		&:nth-of-type(2){
			display: none;
		}

		&:hover,
		&:focus{
			color: $blue;
			text-decoration: none;
		}
	}
}

.widget-section{
	padding: 41px 21px;

	@media (max-width: $b3){
		display: none;
	}

	h3{
		@include font-size(16);
		margin-bottom: 22px;
		font-weight: 700;
		color: black;
	}

	h4{
		font-weight: 700;
		color: black;
		line-height: 19px;
		margin: 0;
	}

	.yith-wcan-sort-by ul.orderby li.orderby-wrapper > a.active:before,
	a.yith-wcan-onsale-button.active:before,
	a.yith-wcan-instock-button.active:before,
	a.yith-wcan-price-link.active:before,
	ul.yith-wcan-list li.chosen > a:before{
		color: $red;
		content: '×';
		font-family: $main-font;
		font-size: 1.5em;
		height: 1em;
		width: 1em;
		line-height: 16px;
		font-weight: 400;
		display: inline-block;
		margin-right: 5px;
		text-align: center;
		border-radius: 50%;
		transition: $time;
	}

	ul.yith-wcan-list li.chosen > a{

		&:hover,
		&:focus{

			&:before{
				background-color: $red;
				color: white;
			}
		}
	}

	ul{
		margin-bottom: 15px;

		li{
			padding-left: 0;

			a{
				text-decoration: none;
				color: black;
				line-height: 19px;

				&:hover,
				&:focus{
					color: $blue;
				}
			}

			.count{
				@include font-size(14);
				color: black;

				&:before{
					content: '(';
				}

				&:after{
					content: ')';
				}
			}
		}
	}
}

.page-wrapper .container{
	width: calc(100% - 18px - 18px);

	@media (max-width: $b4){
		width: calc(100% - 17px - 17px);
	}
}

.single-product{

	.quantity{
		display: none;
	}
}

.searchandfilter.searchandfilter{

	li{
		padding: 0;
		width: 100%;

		label{
			width: 100%;
		}

		select{
			width: 100%;
			margin-bottom: 2px;
			line-height: 30px;
			height: 30px;
			padding-right: 35px;
			background-image: url(../images/select-icon-drill.svg);
			background-repeat: no-repeat;
			border: none!important;
		}
	}

	[type="submit"]{
		width: auto;
	}
}

.widget-section.hide-filter{
	display: none;
}
