footer{
	position: relative;

	.footer-top-outer{
		background-color: $grey-dark;
		padding: 40px 0 43px;

		*{
			color: white;
		}

		h4{
			@include font-size(16);
			font-weight: 700;
			color: $yellow;
		}

		.top{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: 11px;
			padding-bottom: 19px;

			@media (max-width: 949px){
				flex-direction: column;

				.social-menu{
					justify-content: flex-start;
					margin-top: 12px;
				}
			}

			.number{
				@include font-size(22);

				&:hover,
				&:focus{
					color: $yellow;
					text-decoration: none;
				}
			}

			.social-menu{

				a{
					min-width: 0;

					*{
						transition: $time;
						min-width: 0;
					}

					&:hover,
					&:focus{

						*{
							color: $yellow;

						}
					}
				}
			}
		}

		.middle.middle.middle.middle.middle{

			label{
				display: none;
			}

			form{
				display: flex;
				flex-wrap: wrap;

				@media (max-width: $b3){
					width: 200px;
					margin: 0;
				}
			}

			.gform_footer.gform_footer{
				padding-top: 0;
			}

			.gform_body{
				flex-grow: 1;

				@media (max-width: $b3){
					width: 100%;

					input{
						margin: 0;
					}
				}
			}

			.gform_footer{

				@media (max-width: $b3){
					width: 100%;
				}
			}

			#field_1_1{
				width: 100%;
			}

			[type="text"]{
				background-color: transparent;
			}

			[type="submit"]{
				padding: 6.5px 12px;
				background-color: $white;
				color: $grey-dark!important;

				&:hover,
				&:focus{
					background-color: $yellow;
				}
			}

			::-webkit-input-placeholder {
				color: white;
			}

			:-moz-placeholder { /* Firefox 18- */
				color: white;
			}

			::-moz-placeholder {  /* Firefox 19+ */
				color: white;
			}

			:-ms-input-placeholder {
				color: white;
			}
		}

		.bottom{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-end;
			padding-top: 9px;

			@media (max-width: $b3){
				flex-direction: column;
				align-items: flex-start;

				img{
					margin-top: 24px;
				}
			}
		}

		.copy{
			@include font-size(12);
			line-height: 1.4em;
		}
		.copy{

			a{
				position: relative;

				&:hover,
				&:focus{
					color: $yellow;
					text-decoration:none;
				}
			}
		}

		.footer-menu{

			li{
				margin-bottom: 2px;

				a{

					font-weight: 300;
					line-height: 1.1em;
					display: inline-block;
					color: $white;
					text-decoration: none;

					&:hover,
					&:focus{
						color: $yellow;
						text-decoration:none;
					}
				}
			}
		}
	}

	.footer-bottom-outer{
		padding-top: 20px;
		padding-bottom: 20px;

		.by{
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			@media (max-width: $b4){
				flex-direction: column;

			}
		}

		img{
			margin-right: 38px;

			@media (max-width: $b4){
				margin-right: 0;
				margin-bottom: 5px;
			}

			&:last-of-type{
				margin-left: 13px;

				@media (max-width: $b4){
					margin-left: 0;
					margin-top: 5px;
					margin-bottom: 0;
				}
			}
		}
	}
}
