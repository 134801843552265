//!!BUTTON
button:focus,
.btn:focus{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -5px;
	max-width: calc(100% + 6px);

	.btn{
		margin: 0 5px 5px;
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn{
	@include inline;
	padding: 8px 20px;
	font-size: 14px!important;
	font-size: 1.4rem!important;
	text-decoration: none!important;
	line-height: 1.4em;
	border: none;
	color: $blue!important;
	font-weight: 400;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $main-font;
	position: relative;
	border-radius: 0;
	transition: $time;
	text-transform: uppercase;
	background-color: $yellow;

	@media (max-width: $b3){
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	&:hover,
	&:focus{
		outline: none;
		background-color: $blue;
		color: $yellow!important;
	}

	&.big{
		padding: 34.5px 8px;
		font-size: 24px!important;
		font-size: 2.4rem!important;
	}

	&.small{
		min-width: 123px;
		padding: 12px 10px;
		font-size: 13px!important;
		font-size: 1.3rem!important;
	}
}
